<template>
  <!--  ; 'background':content_bgColor[prodType]}"-->
  <div class="board">
    <div class="board_title">{{ board_title[prodType] }}</div>
    <div class="board_content">
      <div class="board_content_head" :style="{
        'background': '-webkit-linear-gradient(left,' +content_bgColor[prodType]+', white)', /* Safari 5.1 - 6.0 */
        'background': '-o-linear-gradient(right,'+content_bgColor[prodType]+', white)/* Opera 11.1 - 12.0 */ ',
        'background': '-moz-linear-gradient(right'+content_bgColor[prodType]+', white)/* Firefox 3.6 - 15 */ ',
        'background': 'linear-gradient(to right,'+content_bgColor[prodType]+', white)/* 标准的语法 */'}">
        <div class="icon_type">
          <img :src="icon_type[prodType]"/>
        </div>
        <div class="logo_title">{{ board_title[prodType] }}</div>
      </div>
      <div class="board_content_details">
        <div class="detail">
          <div class="eve">
            <div class="color_squre" :style="{'background': '#5D7DEC'}"></div>
            <div class="eve_label">今日生产方量</div>
            <div class="eve_data">
              <ICountUp :startVal="0" :endVal="boardData.dayWeight" :options="optionDecimal" :duration="2.5"
                        class="bold DIN"/>
              <div class="eve_label">方</div>
            </div>
          </div>
          <div class="eve">
            <div class="color_squre" :style="{'background': '#C27DFF'}"></div>
            <div class="eve_label">当前在线企业</div>
            <div class="eve_data">
              <ICountUp :startVal="0" :endVal="boardData.currProductionComp" :options="options" :duration="2.5"
                        class="bold DIN font-size-16"/>
              <div class="eve_label">个</div>
            </div>
          </div>
        </div>
        <div class="detail">
          <div class="eve">
            <div class="color_squre" :style="{'background': '#FDAD42'}"></div>
            <div class="eve_label">本月生产量</div>
            <div class="eve_data">
              <ICountUp :startVal="0" :endVal="boardData.monthWeight" :options="options" :duration="2.5"
                        class="bold DIN font-size-16"/>
              <div class="eve_label">方</div>
            </div>
          </div>
          <div class="eve">
            <div class="color_squre" :style="{'background': '#4EF0C5'}"></div>
            <div class="eve_label">异常企业</div>
            <div class="eve_data">
              <ICountUp :startVal="0" :endVal="boardData.exceptionComp" :options="options" :duration="2.5"
                        class="bold DIN font-size-16"/>
              <div class="eve_label">个</div>
            </div>
          </div>
        </div>
        <div class="detail">
          <div class="eve">
            <div class="color_squre" :style="{'background': '#FF6AB6'}"></div>
            <div class="eve_label">本年生产量</div>
            <div class="eve_data">
              <ICountUp :startVal="0" :endVal="boardData.yearWeight" :options="options" :duration="2.5"
                        class="bold DIN"/>
              <div class="eve_label">方</div>
            </div>
          </div>
          <div class="eve">
            <div class="color_squre" :style="{'background': '#396CE2'}"></div>
            <div class="eve_label">生产线总数</div>
            <div class="eve_data">
              <ICountUp :startVal="0" :endVal="boardData.queuCount" :options="options" :duration="2.5"
                        class="bold DIN font-size-16"/>
              <div class="eve_label">条</div>
            </div>
          </div>
        </div>
        <div class="detail">
          <div class="eve">
            <div class="color_squre" :style="{'background': '#63B7FF'}"></div>
            <div class="eve_label">企业总数</div>
            <div class="eve_data">
              <ICountUp :startVal="0" :endVal="boardData.compCount" :options="options" :duration="2.5"
                        class="bold DIN font-size-16"/>
              <div class="eve_label">个</div>
            </div>
          </div>
          <div class="eve">
            <div class="color_squre" :style="{'background': '#F57A7A'}"></div>
            <div class="eve_label">当前在线生产线</div>
            <div class="eve_data">
              <ICountUp :startVal="0" :endVal="boardData.currOnlineQueuCount" :options="options" :duration="2.5"
                        class="bold DIN font-size-16"/>
              <div class="eve_label">条</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ICountUp from 'vue-countup-v2'

export default {
  name: 'prod-board',
  props: {
    prodType: {
      type: [String],
      required: false,
      default: '1'
    },
    boardData: {
      type: [Object],
      required: false,
      default: () => {
        return {
          compCount: 0,
          currOnlineQueuCount: 0,
          currProductionComp: 0,
          dayWeight: 0,
          exceptionComp: 0,
          monthWeight: 0,
          queuCount: 0,
          type: 0,
          yearWeight: 0
        }
      }
    }
  },
  components: {
    ICountUp
  },
  data () {
    return {
      optionDecimal: {
        useEasing: true,
        useGrouping: false,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
        decimalPlaces: 2
      },
      options: {
        useEasing: true,
        useGrouping: false,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      },
      content_bgColor: { // 默认看板颜色,
        1: '#1532E3',
        2: '#009F8C',
        3: '#6B21D6'
      },
      strong_bgColor: {
        1: '#3342AA',
        2: '#019482',
        3: '#621ACA'
      },
      board_title: {
        1: '商砼生产',
        2: '砂浆生产',
        3: '水泥生产'
      },
      icon_type: {
        1: require('@/assets/imgs/prod_manage/logo_concrete.png'),
        2: require('@/assets/imgs/prod_manage/logo_mortar.png'),
        3: require('@/assets/imgs/prod_manage/logo_cement.png')
      }
    }
  },
  computed: {},
  methods: {},
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.board {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .board_title {
    width: 100%;
    color: #000000;
    font-size: 16px;
    font-family: 'PingFang SC';
    font-weight: bold;
  }

  .board_content {
    margin-top: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: white;
    .board_content_head {
      display: flex;
      flex-direction: row;
      border-radius: 2px;
      padding-left:5px;
      .icon_type {
        width:55px;
        height:55px;
        display: flex;
        display: -webkit-flex;
        align-items:center;
        justify-content:center;
      }

      .logo_title {
        display: flex;
        display: -webkit-flex;
        align-items:center;
        justify-content:center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
      }

    }

    .board_content_details {
      display: flex;
      flex-direction: row;
      width:100%;
      padding-top:30px;
      padding-left:20px;
      .detail {
        display: flex;
        flex-direction: column;
        width:calc(25% - 20px);
        margin-right:20px;
        padding-bottom: 20px;
        box-shadow: 0px 1px 9px 0px rgba(202, 197, 255, 0.11);
        border-radius: 2px;
        .eve{
          display: flex;
          flex-direction: row;
          align-items:center;
          height:50px;
          width:100%;
          border-bottom: 1px solid #F3F5F8;
          .color_squre{
            width:7px;
            height:18px;

          }
          .eve_label{
            margin-left:10px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #6C7B8A;
            vertical-align: bottom ;
          }
          .eve_data {
            color: #000000;
            display: flex;
            flex:1;
            flex-direction: row;
            justify-content: flex-end;
          }

        }
      }
    }
  }
}
</style>
