<template>
  <page-container>
    <div class="layout">
      <!--        <div class="top_bar" v-if="top_bar_show">
            <div class="bar_content" >
                <img class="content_img" src="@/assets/imgs/prod_manage/message.png"/>
                <p class="content_txt">{{content_text}}</p>
                <a href="/" class="detail_txt">查看详情</a>
                <a href="javascript:void(0)" class="content_close"  @click="top_bar_show=false"><img src="@/assets/imgs/common/close.png"/></a>
            </div>
        </div>-->
      <div class="data_board">
        <div class="prod_board">
          <prodBoard
            v-if="boardData"
            prod-type="1"
            :board-data="boardData.concrete"
          />
        </div>
        <div class="prod_board">
          <prodBoard
            v-if="boardData"
            prod-type="2"
            :board-data="boardData.mortar"
          />
        </div>
      </div>
      <div class="data_board">
        <div class="prod_board">
          <prodBoard
            v-if="boardData"
            prod-type="3"
            :board-data="boardData.cement"
          />
        </div>
      </div>
    </div>
  </page-container>
</template>

<script>
import { getIndexStatisticsData } from '@/apis/prod_manage/common'
import ProdBoard from './prodBoard'
export default {
  name: 'Index',
  components: { ProdBoard },
  data () {
    return {
      content_text: '你好，欢迎使用，如有疑问请咨询客服或查看帮助中心',
      top_bar_show: true,
      boardData: null
    }
  },
  watch: {
    data () {
      this.boardData = this.data.boardData
    }
  },
  mounted () {
    getIndexStatisticsData({})
      .then((res) => {
        if (res.code == 200) {
          const data = res.data
          this.boardData = {}
          this.boardData.concrete = data.filter((item) => item.type == '1')[0]
          this.boardData.mortar = data.filter((item) => item.type == '2')[0]
          this.boardData.cement = data.filter((item) => item.type == '3')[0]
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  // height: 100%;
  overflow: hidden;
  background: #f0f2f5;
  .top_bar {
    height: 40px;
    padding: 20px 0px 20px 10px;
    width: calc(100% - 22px);
    border: 1px solid #a5efb1;
    background-color: #f5fff4;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .bar_content {
      display: flex;
      flex-direction: row;
      justify-items: left;
      padding-right: 20px;
      .content_img {
        height: 11px;
        width: 12px;
      }
      .content_txt {
        font-size: 12px;
        margin-left: 6px;
        margin-right: 47px;
      }
      .detail_txt {
        font-size: 12px;
        color: #04980b;
      }
      .detail_txt:hover {
        color: #13ce66;
      }
      .content_close {
        margin-left: auto;
        //  border:1px #666 solid;
        width: 13px;
        height: 13px;
        img {
          width: 12px;
          height: 12px;
        }
      }
      .content_close:hover {
        border: 1px #7e8c8d solid;
      }
    }
  }
  .data_board {
    width: calc(100%);
    height: 100%;
    display: flex;
    flex-direction: column;
    .prod_board {
      width: calc(100% - 5px);
      height: auto;
      margin-top: 20px;
    }
  }
}
</style>
